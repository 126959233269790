.CardTitle {
    font-family: 'Noto Sans', sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: #383838;
    margin-bottom: 5px;
}

.CardSubtitle {
    font-family: 'Noto Sans', sans-serif;
    font-size: 13px;
    color: #383838;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    letter-spacing: 1.3px;
}

.CardText {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: black;
    margin-left: 0px;
    margin-top: 15px;
    text-align: left;
}