.prj-modal, .prj-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 11;
}

.prj-overlay {
    background: rgba(49,49,49,0.8);
    z-index: 100;
}
.prj-modal-content {
    padding: 50px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    /* padding: 14px 28px; */
    border-radius: 3px;
    max-width: 1150px;
    min-width: 1000px;
    min-height: fit-content;
    z-index: 101;
}

.prj-close-modal {
    position: absolute;
    top: 3px;
    right: 15px;
    padding: 5px 7px;
    font-size: 2rem;
    transition: 0.2s ease-in-out;
    color: grey;
}
.prj-close-modal:hover {
    color: #4484b4;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.prj-text{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 30%;
    text-align: justify;
}

@media screen and (max-width: 768px) {
    .prj-modal-content {
      width: 100%;
      height: 100%;
      min-width: 0px;

    }
  
    .prj-text{
        width: 90%
    }
  }