.projects-page {
    display: grid;
    z-index: 1;
    width: 100%;
    margin-block: auto;
    padding: 0 24px;
    height: fit-content;
    justify-content: center;
    padding: 20px;
    overflow-x: clip;
}
.extraSpace{
    margin-bottom: 4rem;
}
@media screen and (max-width: 1400px) {
    .projects-page {
        display: grid;
        z-index: 1;
        height: fit-content;
        max-width: 90%;
        margin-block: auto;
        padding: 0 12px;
        justify-content: center;
    }
    .project-card {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 90%;
        max-width: 500px;
    }
}

.project-card {
    display: block;
    margin: 10px auto;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 80vmin;
    max-width: 1600px;
    & > img {
        width: 200px;
    }
    & > video {
        width: fit-content;

    }
}

.project-card img,
.project-card video {
    width: 100%;
    /* aspect-ratio: 16/9; */
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
}

.project-card h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.project-card p {
    font-size: 1rem;
    color: #666;
}

@media (max-width: 768px) {
    .project-card {
        flex: 0 0 calc(100% - 20px);
    }
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80vmin; /* Could be more or less, depending on screen size */
    height: fit-content;
}
.modal-content > video,
.modal-content > img {
    height: auto;
    width: 80vmin;
}
.modal-content > .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    &:hover, &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
}

.subteams-page {
    display: grid;
    z-index: 1;
    width: 100%;
    margin-block: auto;
    padding: 0 24px;
    height: fit-content;
    justify-content: center;
    padding: 20px;
    overflow-x: clip;
}
@media screen and (max-width: 1400px) {
    .subteams-page {
        display: grid;
        z-index: 1;
        height: fit-content;
        max-width: 90%;
        margin-block: auto;
        padding: 0 12px;
        justify-content: center;
    }
}
@media screen and (max-width: 768px) {
    .subteams-page {
        display: flex;
        z-index: 1;
        height: fit-content;
        max-width: 80vw;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin: auto;
        margin-block: auto;
        padding: 0 12px;
        justify-content: center;
        flex-wrap: wrap;
        grid-column: "subteams-subteam-content";
    }

    .subteam-members{
        width: 58%;
    }
    .subteam-content {
        align-items: center;
    }
}

.subteams-header {
    margin: auto;
    padding: auto;
    text-align: center;
    width: 100%;
    border-top: 2px solid #696969;
    border-bottom: 2px solid #696969;
}

.subteams-container {
    padding-top: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    width: 1100px;
    height: fit-content;
    row-gap: 60px;
    flex-direction: row;
    margin: 0;


    & > .subteam-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: left;
        padding-left: 20px;
        width: 510px;
        height: fit-content;
        
        & > .subteam-photo {
            width: 98%;
            height: 35vh;
            margin-bottom: 0;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            &:hover {
                transform: scale(1.03);
                transition: .2s ease-in-out;
            }
        }

        & > .subteam-name {
            font-size: 21px;
            font-weight: bold;
            color: #383838;
            margin-bottom: 5px;
        }
        & > .subteam-lead {
            font-size: 13px;
            color: #383838;
            margin-left: 0;
            margin-bottom: 5px;
            margin-top: 0;
            letter-spacing: 1.3px;
        }
        & > .subteam-members {
            font-size: 12px;
            color: black;
            margin: 0;
        }
    }
}

/*Subteam Modal Stuff*/
body.active-modal {
    overflow-y: hidden;
}

.st-modal, .st-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 11;
}

.st-overlay {
    background: rgba(49,49,49,0.8);
    z-index: 100;
}
.st-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    /* padding: 14px 28px; */
    border-radius: 3px;
    max-width: 1150px;
    min-width: 1000px;
    min-height: fit-content;
    z-index: 101;
}

.st-modal-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 40px;
  column-gap: 20px;
}

.st-modal-photo {
  
  width: 600px;
  margin-bottom: 0px;
}

.st-modal-text {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.st-modal-paragraph {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  color: black;
  margin-left: 0px;
  margin-top: 0;

}

.st-name{
    font-size: 28px;
}

.st-lead{
    font-size: 20px;
    color: gray;
    margin: 0 0 6px 0;
}

.st-members{
    font-weight: 700;
    margin: -20px 40px 40px 40px;
}

.st-close-modal {
    position: absolute;
    top: 3px;
    right: 15px;
    padding: 5px 7px;
    font-size: 2rem;
    transition: 0.2s ease-in-out;
    color: grey;
}
.st-close-modal:hover {
    color: #4484b4;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .st-modal-content {
      width: 100%;
      height: 100%;
      min-width: 0px;

    }
  
    .st-modal-photo {
      display: none;
    }
  }

/*End of subteam modal stuff*/
