
.HeroShop {
    background-color: #2a2a2a;;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

