/* most of this code was taken from https://www.youtube.com/watch?v=Nl54MJDR2p8. Thanks!*/


.Navbar {
  background: white;
  height: 77px;
  /*margin-top: -80px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: 0.3s all ease;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.NavbarTransparent {
  background: transparent;
  height: 77px;
  /*margin-top: -80px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: 0.3s all ease;
}

@media screen and (max-width: 960px) {
  .Navbar {
    transition: 0.2s all ease;

  }
}

.NavbarContainer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1550px;
}

.NavbarLogo {
  color: white;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-left: 0px;
  font-weight: bold;
  font-family: 'Josefin Sans';
  text-decoration: none;
  
  transition: "0.2s ease-in-out"
}

.FilterBlack {
  /* stuff */
  box-sizing: border-box;
}

.FilterWhite {
  filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(57deg) brightness(107%) contrast(110%);
}


.NavbarLogoImg {
  color: white;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-decoration: none;
  
  transition: "0.2s ease-in-out"
}

/*only shows when the sidebar is clicked, swag style*/
.NavbarMobileIcon {
  display: none;
}

.NavbarMenu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -80px;
  width: fit-content;
}

.NavbarButtonContainer {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 0px;
  & > .resource-link {
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Noto Sans', sans-serif;
    letter-spacing: 2px;
    transition: .2s ease-in-out;
    font-size: 20px;
    padding: 5px;
    border: 3px solid;
    border-color: transparent;
    &:hover {
      transform: scaleX(1.05);
      border: 3px solid rgba(0, 69, 255, 0.69);
      border-radius: 5px;
    }
  }
}

.NavbarItem {
  height: 80px;
  font-size: 22px
}

.NavbarLink {
  color: black;
  display: flex;
  align-items: center;
  padding: 0 1.3rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 2px;
  transition: 0.2s ease-in-out;
  font-size: 20px;
}

.NavbarLinkTransparent {
  color: white;
  display: flex;
  align-items: center;
  padding: 0 1.3rem;
  height: 80px;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 2px;
  transition: 0.2s ease-in-out;
  font-size: 20px;
}

.NavbarLinkTransparent:hover {
  color: #4484b4;
  display: flex;
  align-items: center;
  padding: 0 1.1rem;
  height: 80px;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 2px;
  transition: 0.2s ease-in-out;
  font-size: 20px;
}

.NavbarLink:hover {
  color: #4484b4;
  display: flex;
  align-items: center;
  padding: 0 1.1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 2px;
  transition: 0.2s ease-in-out;
  font-size: 20px;
}



@media screen and (max-width: 990px) {
  .NavbarMobileIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }

  .NavbarMenu {
    display: none;
  }

  .NavbarLogo {
    display: none;
  }

}
.NavbarIconTextTransparent {
  color: white;
  transition: 0.2s ease-in-out;
}
.NavbarIconText {
  color: black;
  transition: 0.2s ease-in-out;
}

.NavbarIconText:hover {
  color: #4484b4;
  transition: 0.2s ease-in-out;
}