.InfoCont {
    color: #383838;
    background: white;
    /* border-top: 3px solid grey; */
}

.InfoContBlack {
    color: white;
    background: #2a2a2a;
}

.InfoWrap {
    display: grid;
    z-index: 1;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 24px;
    justify-content: center;
}

/* text-first info row */
/* column 1 = text, column 2 = images */
.InfoRowT {
    display: grid;
    column-gap: 2vw;
    align-items: center;
    grid-template-areas: 'col1 col2';
}

/*Picture-first info row*/
.InfoRowI {
    display: grid;
    column-gap: 2vw;
    align-items: center;
    grid-template-areas: 'col2 col1'
}

.Col1 {
    text-align: center;
    grid-area: col1;
    width: 45vw;
}

.Col2 {
    text-align: center;
    grid-area: col2;
    width: 45vw;
}

.Image {
    width: 35vw;
    height: 22.72vw;
    border-radius: 10px;
}

.HeadingBlack {
    font-family: "Noto Sans", "bold";
    font-size: 48px;
    color: #383838;
    margin: 0px;
}

.HeadingWhite {
    font-family: "Noto Sans", "bold";
    font-size: 48px;
    color: white;
    margin: 0px;
}

.Text {
    font-size: 20px;
    font-family: "Noto Sans", "bol";
}

@media screen and (max-width: 768px) {
    .InfoRowT {
        grid-template-areas: 'col1' 'col2';
    }

    .Text {
        font-size: 16px;

    }

    .InfoRowI {
        grid-template-areas: 'col1' 'col2';
    }

    .Col1 {
        width: 90vw;
        padding: 50px 0;
    }

    .Col2 {
        width: 90vw;
        padding-bottom: 50px;
    }

    .InfoWrap {
        padding: 0px 0px;
    }

    .Image {
        width: 85vw;
        height: 54.4vw;
    }

    .Heading {
        font-size: 36px;
    }
}