
.Hero4 {
    background-image:linear-gradient(45deg, rgba(56, 115, 179, 0.623) 0%, rgba(16, 60, 106, 0.68) 100%), url('../Images/IMG_0331.jpg');
    background-color: black;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .TeamContainer {
      padding-top: 75px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 1100px;
      height: fit-content;
      row-gap: 60px;
      flex-direction: row;
      margin: 0px 0px 0px 0px;
    }
    
    .TeamPhoto {
        height: 300px;
        width: 288.75px;
        margin-bottom: 0px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
  
    }
  
    .TeamPhoto:hover {
      transform: scale(1.03);
      transition: 0.2s ease-in-out;
    }
  
  
    .TeamContent { 
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      padding-left: 20px;
      width: 309px;
      height: fit-content;
  
    }
    
    .TeamName {
      font-family: 'Noto Sans', sans-serif;
      font-size: 21px;
      font-weight: bold;
      color: #383838;
      margin-bottom: 5px;
    }
    
    .TeamHeader {
      font-family: 'Noto Sans', sans-serif;
      font-size: 13px;
      color: #383838;
      margin-left: 0px;
      margin-bottom: 5px;
      margin-top: 0px;
      letter-spacing: 1.3px;
    }
    
    .TeamText {
      font-family: 'Noto Sans', sans-serif;
      font-size: 16px;
      color: black;
      margin-left: 0px;
    }
    
    
    .ContentBox {
      width: 100%;
      display: flex;
      flex-direction : column;
      height: fit-content;
      background-color: white;
      color: black;
      align-items: center;
      margin-bottom: 60px;
    
    }
    
    .HeaderContainer {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .HeaderTitle {
        font-size: 48px;
        color: #383838;
        font-weight: bold;
        font-family: 'Noto Sans', sans-serif;
        margin-bottom: 0px;
    }
    
    .HeaderText {
        font-size: 48px;
    }
    
    .border1 {
      border-top: 3px solid #383838;
      width: 800px;
      border-radius: 10px;
    }
  
    @media screen and (max-width: 1100px) {
      .TeamContainer{
        width: 768px;
      }
  
      .border1 {
        width: 550px;
      }
    }
  
    @media screen and (max-width: 650px) {
      .TeamContainer{
        width: 360px;
      }
  
      .border1 {
        width: 360px;
      }
    }
  
  
  
    /*Modal Stuff*/
  
    body.active-modal {
      overflow-y: hidden;
  }
  
  .btn-modal {
      padding: 10px 20px;
      display: block;
      margin: 100px auto 0;
      font-size: 18px;
  }
  
  .modal, .overlay {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: 11;
  }
  
  .overlay {
      background: rgba(49,49,49,0.8);
      z-index: 100;
  }
  .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1.4;
      background: #f1f1f1;
      /* padding: 14px 28px; */
      border-radius: 3px;
      max-width: 1000;
      min-width: 850px;
      /*keep track of this*/
      max-height: 90%;
      min-height: fit-content;
      z-index: 101;
  }
  
  .modal-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 40px;
    column-gap: 20px;
  }
  
  .modal-photo {
    height: 500px;
    width: 385px;
    margin-bottom: 0px;
  }
  
  .modal-text {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  
  .modal-socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
    align-items: center;
    height: fit-content;
    width: fit-content;
  }
  
  .modal-paragraph {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: black;
    margin-left: 0px;
    margin-top: 0;
    text-align: justify;
  }
  
  
  .close-modal {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 5px 7px;
      font-size: 1.5rem;
      color: black;
  }
  
  /*Bullet point: &#x2022;*/
  
  .close-modal-text {
    transition: 0.2s ease-in-out;
    color: grey;
  
  }
  
  
  .close-modal-text:hover {
    color: #4484b4;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .IconText2 {
    color: grey;
    transition: 0.2s ease-in-out;
  }
  
  .IconText2:hover {
    color: #4484b4;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .ListFlex {
    display: flex;
    flex-wrap: wrap;
    column-gap: 100px;
  }
  
  .ListItem {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: black;
    margin-top: 0px;
  }
  
  .tempButton {
    width: 50px;
  }
  
  @media screen and (max-width: 768px) {
    .modal-content {
      width: 80%;
      min-width: 0px;
    }
  
    .modal-photo {
      display: none;
    }
  }
  
  .FooterWrapper {
    height: 280px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}