* {
  ::-webkit-scrollbar { display: none; }
}

.InfoContainer {
    color: #fff;
    /* background: #070d0d; */
    background: white;
    padding-top: 50px;
    padding-bottom: 0px;

   }
   .InfoContainerBody {
    color: black;
    background: white;
    padding-top:5px;
    padding-bottom:5px;
   }
   .InfoContainerBody2 {
    color: black;
    background: white;
    padding-top:75px;
    padding-bottom:5px;
   }
   @media screen and (max-width: 768px) {
    .InfoContainer {
    color: black;
    /* background: #070d0d; */
    background: white;
    padding-top: 50px;
    }
    .InfoContainerBody {
    padding-top: 100px
    }
   }
   /*
   Content here is justified through justify-content (center). this leaves our side-by-side grids in the middle of the page.
   This grid only contains one value, but is used to "hold" the subgrid (in this case our homeRow, which then has individual columns.
    */
   .InfoWrapper {
    display: grid;
    
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    height: fit-content;
    /* border: 3px solid blue; */
   }
   @media screen and (max-width: 1400px) {
    .InfoWrapper {
    display: grid;
    z-index: 1;

    height: fit-content;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 12px;
    justify-content: center;
    }
   }

   .Column1 {
    grid-area: col1;
   }
   
   .Column2 {
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
   }

   /*Intro Column*/
   .InfoRow1 {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1';
   }
   .InfoRow1>.Column1 {
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1300px;
    text-align: center;
    border-radius: 25px;
    background-color: #deebff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.10);
    background-color: #DEEBFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ddedfd' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23ddeffa' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23ddf0f6' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23ddf0f2' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23DEEEEE' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23e5f1f1' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ebf5f5' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23f2f8f8' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23f8fcfc' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23FFFFFF' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
   }

   /* .SponsorContainer {
    background-color: #f2f2f2;
    color: black;
    background: white;
    width: 100%;
    display: none;
    
    & > .SponsorWrapper {
      display: flex;
      z-index: 1;
      width: 100%;
      max-width: 1300px;
      margin-block: auto;
      padding: 0 24px;
      justify-content: center;
      height: fit-content;
      border: 3px solid blue;
    }
    @media screen and (max-width: 1300px) {
      .SponsorWrapper {
        display: flex;
        z-index: 1;
        height: fit-content;
        max-width: 90%;
        margin-block: auto;
        padding: 0 12px;
        justify-content: center;
      }
    }
    .SponsorColumn1 {
      grid-area: col1;
    }
    .SponsorColumn2 {
      margin-bottom: 15px;
      padding: 0 15px;
      grid-area: col2;
    }
    .SponsorRow1 {
      display: grid;
      grid-auto-columns: minmax(auto, 1fr);
      align-items: center;
      grid-template-areas: 'col1';
      & > .SponsorColumn1 {
        padding-inline: 30px;
        padding-block: 20px;
        max-width: 1300px;
        text-align: center;
        border-radius: 25px;
        background-color: #deebff;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ddedfd' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23ddeffa' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23ddf0f6' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23ddf0f2' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23DEEEEE' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23e5f1f1' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ebf5f5' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23f2f8f8' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23f8fcfc' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23FFFFFF' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");

      }
    }
    .Sponsors {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      .BluefireSponsors {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        width: 100%;
        align-items: center;
        gap: 10px;
        & > li {
          list-style: none;
        }
      }
    }
   } */

   .HomepageContentBox {
    width: 100%;
    display: flex;
    flex-direction : column;
    height: fit-content;
    background-color: white;
    color: black;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .cards__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;
    padding: 20px;
    flex-flow: row wrap;
    row-gap: 50px;
    column-gap: 25px;
    align-items: center;
    max-width: 1300px;
    width: 90%;
    margin: 0 auto;
    justify-content: center;
  }
  @media screen and (max-width: 1048px) {
    .cards__container {
      grid-template-columns: 1fr;
    }
  }
  .cards__container > div:nth-child(3) {
    grid-column: span 2;
  }
  @media screen and (max-width:1048px) {
    .cards__container > div:nth-child(3) {
      grid-column: span 1;
    }
  }

  .divider {
    width: 768px;
    color: #383838;
    border-radius: 3px;
    border-top: 3px solid #383838;
    box-shadow: none;
    text-shadow: none;
  }