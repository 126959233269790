.resources-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    max-width: 1200px;
    margin: auto; 
    padding: 20px;
}

.resources-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 40px;
}

.resources-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center; 
    padding: 0;
}

.resource-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 10px;
}

.resource-card:hover {
    transform: translateY(-5px);
}

.resource-image {
    width: auto; 
    height: 120px; 
    object-fit: contain;
    margin-bottom: 10px; 
}

.resource-name {
    font-size: 1.2rem;
    margin-top: 5px; 
    margin-bottom: 0;
}


@media (min-width: 768px) {
    .resources-grid {
        flex-direction: row; 
        justify-content: center; 
        flex-wrap: wrap; 
        
    }
    .resources-container{
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .resource-card {
        margin: 0 10px; 
    }
}