.HeroVid {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.Hero {
    /* background: url('../Images/editedBackground.png') center center/cover no-repeat; */
    /* background-color: black; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.HeroHeader {
    color: white;
    font-size: 70px;
    margin-top: 48px;
    justify-self: center;
    align-self: center;
    font-family: 'Josefin Sans';
    text-align: center;
    width: 90vw
}

.HeroText {
    color: white;
    font-size: 28px;
    font-family: 'Montserrat';
    font-weight: bold;
    text-align: center;
    letter-spacing: 8px
        /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif */
}

.HeroTextBottom {
    color: white;
    font-size: 24px;
    /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif */
}

@media screen and (max-width: 1000px) {
    .HeroHeader {
        font-size: 48px;
        margin-bottom: 36px;
    }

    .HeroText {
        font-size: 19.2px;
    }
}

@media screen and (max-width: 612px) {
    .HeroHeader {
        font-size: 40px;
        margin-bottom: 42px;
    }

    .HeroText {
        font-size: 19.2px;
    }
}