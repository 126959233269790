.Hero6 {
  background-image:linear-gradient(rgba(56, 115, 179, 0.623) 0%, rgba(16, 60, 106, 0.68) 100%), url('../Images/SponsorBanner.jpg');
  background-color: black;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

}

.SponsorIntroBox {
    width: 100%;
    display: flex;
    flex-direction : column;
    height: fit-content;
    background-color: white;
    color: black;
    align-items: center;
}

.SponsorContainer {
  padding-top: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 1500px;
  height: fit-content;
  column-gap: 60px;
  flex-direction: row;
  row-gap: 60px;
  padding-bottom: 75px;
}

.SponsorRow {
  display: flex;
  flex-direction: row;
  flex: 30% 50%;
  height: fit-content;
  align-items: center;
  width: 70%;
  justify-content: space-between;
}

.sponsor-photo {
  width: 40%;
  height: 16%;
}

.SponsorHeaderTitle {
  font-family: 'Noto Sans', sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #383838;
  margin-bottom: 5px;
  margin-top: 0px;
}

.SponsorName {
  font-family: 'Noto Sans', sans-serif;
  font-size: 21px;
  font-weight: bold;
  color: #383838;
  margin-bottom: 5px;
}

.SponsorTextBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  width: 60%;
  margin-left: 2rem;
}

.SponsorText {
  font-family: 'Noto Sans', sans-serif;
  font-size: 17px;
  color: #383838;
  margin-left: 0px;
  margin-bottom: 05px;
  margin-top: 20px;
  letter-spacing: 1.3px;
}


@media screen and (max-width: 1510px) {
  .SponsorContainer{
    width: 1100px;
  }


}

@media screen and (max-width: 1100px) {
  .SponsorContainer{
    width: 650px;
  }

  .SponsorRow{
    flex-direction: column;
  }

  .sponsor-photo{
    width: 95vw;
  }

  .SponsorTextBox{
    width: 93vw;
  }
}

@media screen and (max-width: 650px) {
  .SponsorContainer{
    width: 96vw;
  }

  .SponsorRow{
    flex-direction: column;
  }

  .sponsor-photo{
    width: 95vw;
  }

  .SponsorTextBox{
    width: 93vw;
  }

  .SponsorHeaderTitle {
    font-size: 20px;
    text-align: center;
    margin-top: -5px;

  }

  .SponsorText {
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
    line-height: 30px;
  }

}