.prj-image{
    object-fit: contain;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    transition: translate 400ms ease-in-out
}

.slider-button{
    text-align: center;
    position: absolute;
    top: 45%;
    width: 30px;
    font-size: 30px;
    background-color: rgb(105,105,105, .5);
    color: white;
    border-radius: 40px;
    border: none;
}

.slider-button:hover{
    background-color: gray;
}

#next{
    right: 1%;
}

#prev{
    left: 1%;
}

.slideshow-container{
    position: relative;
    width: 600px;
    height: 500px;
}

@media screen and (max-width: 768px) {
    .slideshow-container{
        display: none
    }
  }