/*code taken from https://www.youtube.com/watch?v=Nl54MJDR2p8. Thanks!*/

.SidebarContainerNormal {
    position: fixed;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: black;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: 100%;
}

.SidebarContainerHidden {
    position: fixed;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: black;
    display: grid;
    align-items: center;
    top: -100%;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: 0%;

}

.SidebarIcon {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    color: white;
}

.SidebarWrapper {
    color: "white"
}

.SidebarMenu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 80px);
    text-align: center;

}

.SidebarLink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: white;
    cursor: pointer;
    font-family: 'Josefin Sans', 'Trebuchet MS';


}

.SidebarLink:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #4484b4;
    cursor: pointer;
    transition: 0.2s ease-in-out;

}

.SidebarButton {
    display: flex;
    justify-content: center;
}

.SidebarIconText {
    transition: 0.2s ease-in-out;
    color: white;

}

.SidebarIconText:hover {
    color: #4484b4;
    transition: 0.2s ease-in-out;
}

.SidebarImage {
    display: flex;
    align-items: center;
    align-self: center;
    text-align: center;
    justify-self: center;
    width: 100px;
    height: 100px;
    margin-top: 160px;
}

.solidDiv {
    border-top: 1px solid #4484b4
}