.HeroVid2 {
    object-fit: cover;
    width: 100%;
    height: 50vh;
    position: fixed;
    z-index: -1;
}

.Hero2 {
    background-image:linear-gradient(rgba(56, 115, 179, 0.623) 0%, rgba(16, 60, 106, 0.68) 100%), url('../Images/skyline-day.jpg');
    background-color: black;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

}

.HeroHeader2 {
    color: white;
    font-size: 60px;
    margin-top: 100px;
    margin-bottom: 20px;
    justify-self: center;
    align-self: center;
    font-family: 'Noto Sans';
    font-weight: bold;
    text-align: center;
    width: 90vw
}

.HeroHeaderSolo {
    color: white;
    font-size: 60px;
    margin-top: 100px;
    margin-bottom: 60px;
    justify-self: center;
    align-self: center;
    font-family: 'Noto Sans';
    font-weight: bold;
    text-align: center;
    width: 90vw
}

.HeroText2 {
    color: white;
    font-size: 24px;
    margin-bottom: 70px;
    justify-self: center;
    align-self: center;
    font-family: 'Noto Sans';
    text-align: center;
    width: 1200px;
}

@media screen and (max-width: 768px) {
    .HeroHeader2 {
        font-size: 60px;
    }
}

@media screen and (max-width: 1300px) {
    .HeroText2 {
        width: 75%;
    }
}